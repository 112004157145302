//
// Error 5
//

// Initialization of global variables, mixins and functions
@import "../../init";

.error.error-5 {
  .error-title {
    font-size: 7rem !important;
  }
}

@include media-breakpoint-up(md) {
  .error.error-5 {
    .error-title {
      font-size: 14rem !important;
    }
  }
}
