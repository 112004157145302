$grey-200: #dddddd;
$grey-600: #777777;
$blue-50: #f0f8ff;
$blue-400: #339dff;
$blue-500: #0084ff;
$blue-600: #006acc;
$white: #ffffff;

// body,
// html {
//   height: 100%;
//   margin: 0;
//   padding: 0;
//   font-family: "Roboto", sans-serif;
//   background: $white;
//   font-size: 14px;
// }

// h2 {
//   font-size: 18px;
//   margin: 10px 0 0 0;
//   font-weight: normal;
//   color: #111;
// }

// p {
//   font-size: 14px;
//   color: $grey-600;
//   line-height: 18.2px;
//   margin-bottom: 30px;
// }

.emptystate {
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__body {
    width: 100%;
    max-width: 600px;
    padding: 20px 40px;
    box-sizing: border-box;
    text-align: center;
  }
}

.illustration {
  &__container {
    width: 160px;
    height: 160px;
    background-color: $blue-50;
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover .illustration__containerBackground svg {
      animation: pulse 4s ease-in-out infinite;
    }
  }
  &__containerBackground {
    width: 160px;
    height: 160px;
    position: absolute;
    overflow: hidden;
    border-radius: 20px;
  }
  &__elements {
    animation: float 6s ease-in-out infinite;
    background-color: none;
    animation-delay: 2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translatey(5px);
  }
  &__element {
    margin-bottom: 15px;
    background-color: $white;
    width: 140px;
    border-radius: 6px;
    transform: scale(O);
    opacity: 0;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.2);
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--right.illustration__element--1 {
      transform: translatex(-30px);
      animation: popInRight 0.3s ease-in-out;
      animation-fill-mode: forwards;
      transform-origin: top left;
    }
    &--right.illustration__element--3 {
      transform: translatex(-30px);
      animation: popInRight 0.3s ease-in-out 0.6s;
      animation-fill-mode: forwards;
      transform-origin: top left;
      margin: 0;
    }
    &--left {
      transform: translatex(30px);
      animation: popInLeft 0.3s ease-in-out 0.3s;
      animation-fill-mode: forwards;
      transform-origin: top right;
    }
  }
  &__elementType {
    width: 24px;
    height: 24px;
    background-color: $blue-400;
    border-radius: 6px;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-right: 10px;
  }
}

.illustration__elementWireframe {
  width: 100px;
  height: 8px;
  background-color: $grey-200;
  border-radius: 6px;
}

.button {
  background-color: $blue-500;
  padding: 16px 28px;
  width: auto;
  color: $white;
  border-radius: 6px;
  display: inline-block;
  transition: 0.5s ease-in-out;

  &:hover {
    background-color: $blue-600;
  }
}
@keyframes float {
  0% {
    transform: translatey(5px);
  }
  50% {
    transform: translatey(-5px);
  }
  100% {
    transform: translatey(5px);
  }
}

@keyframes popInRight {
  0% {
    transform: scale(0) translatex(0px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translatex(-30px);
    opacity: 1;
  }
}

@keyframes popInLeft {
  0% {
    transform: scale(0) translatex(0px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translatex(30px);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
